
.contact-page {
  max-width: 600px;
  margin: 0 auto;
  padding: 2rem;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  ::v-deep input.input,
  ::v-deep select,
  ::v-deep textarea {
    background-color: #f0f0f0;
  }
}
